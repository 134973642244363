export const rusLng = {
  appHeaderLangButton: 'En',

  titleBlockHeader: 'Виктор Строков',
  titleBlockSubHeader: 'Управление проектами, разработка продуктов, исследования и аналитика',

  projectBlockWorkNowText: 'Сейчас работаю в ',

  projectBlockUsedToWorkText: 'Работал в: ',
  projectBlockUsedToWorkTextNTB: 'Национальной товарной бирже',
  projectBlockUsedToWorkTextMC: 'MaxConference',
  projectBlockUsedToWorkTextTR: 'Thomson Reuters',
  projectBlockUsedToWorkTextKG: 'Концерне Калашников',
  projectBlockUsedToWorkTextLRNPT: 'Лукойл-РНП-Трейдинг',

  projectBlockFoundedText: 'Собственные проекты: ',
  projectBlockFoundedTextVenivi: 'Venivi.ru',
  projectBlockFoundedTextMappNgo: 'MappNgo',

  appFooterContacts: 'Соцсети:',
}

export const engLng = {
  appHeaderLangButton: 'Ru',

  titleBlockHeader: 'Victor Strokov',
  titleBlockSubHeader: 'Project management, product development, research and analytics',

  projectBlockWorkNowText: 'Work at ',

  projectBlockUsedToWorkText: 'Worked at: ',
  projectBlockUsedToWorkTextNTB: 'National Mercantile Exchange',
  projectBlockUsedToWorkTextMC: 'MaxConference',
  projectBlockUsedToWorkTextTR: 'Thomson Reuters',
  projectBlockUsedToWorkTextKG: 'Kalashnikov Group',
  projectBlockUsedToWorkTextLRNPT: 'Lukoil-RNP-Trading',

  projectBlockFoundedText: 'Founded: ',
  projectBlockFoundedTextVenivi: 'Venivi.ru',
  projectBlockFoundedTextMappNgo: 'MappNgo',

  appFooterContacts: 'Socials:',
}
